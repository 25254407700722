import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "../Utils/axios";
import Pagination from "react-js-pagination";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useParams,
  useLocation
} from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Catgallery() {
  const navigate = useNavigate();
  let query = useQuery();
  let title = query.get("title");
  const [paginateData, setPaginateData] = useState([]);
  function handlePageChange(pageNumber) {
    getDataList(pageNumber);
  }

  const [url, setUrl] = useState("");
  const [urlid, setUrlid] = useState("");
  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  const [showedit, setShowedit] = useState(false);
  const [data, setData] = React.useState([]);
  let { id } = useParams();

  React.useEffect(() => {
    getDataList(1);
  }, []);

  let getDataList = async function (page) {
    axios
      .get("/gallery/cat/image/list/" + id + "?page=" + page)
      .then((response) => {
        setData(response.data);
        setPaginateData(response.data);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleeditClose = () => setShowedit(false);
  const handleeditShow = () => setShowedit(true);

  function handleeditShowFN(id, src) {
    handleeditShow();
    setUrl(src);
    setUrlid(id);
    console.log(urlid);
  }

  async function addImage() {
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post("/gallery/cat/image/add/" + id, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        getDataList(1);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function editImage() {
    console.log(urlid);
    const formData = new FormData();
    formData.append("file", file);
    console.log(file);
    axios
      .post("/gallery/cat/image/edit/" + urlid, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        getDataList(1);
        handleeditClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function deleteImage() {
    const formData = new FormData();
    axios
      .post("/gallery/cat/image/delete/" + urlid, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        console.log(res);
        getDataList(1);
        handleeditClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <h1>
        <Button variant="primary" onClick={() => navigate(-1)}>
          <i className="fa-solid fa-backward-step"></i>
        </Button>
        {title}
      </h1>
      <hr />
      <Button variant="primary" onClick={handleShow}>
        ფოტოს დამატება
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>დაამატე ფოტო</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              className="form-control"
              multiple
              accept="image/*"
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" title="დამატება" onClick={addImage}>
            <i className="fa-solid fa-plus"></i>{" "}
            <i className="fa-solid fa-floppy-disk"></i>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showedit} onHide={handleeditClose}>
        <Modal.Header closeButton>
          <Modal.Title>ფოტოს რედაქტირება</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <img src={url} alt="" height="70" />
            <br />
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              className="form-control"
              multiple
              accept="image/*"
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" title="წაშლა" onClick={deleteImage}>
            <i className="fa-solid fa-trash"></i>
          </Button>
          <Button variant="primary" title="შეცვლა" onClick={editImage}>
            <i className="fa-solid fa-floppy-disk"></i>
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="Catgallery-list-container">
        {data.map((item) => {
          return (
            <>
              <div className="Catgallery-list">
                <img
                  src={"https://rigi.ge/laravel/ecom/" + item.img_path}
                  alt=""
                />
                <Button
                  variant="primary"
                  onClick={() =>
                    handleeditShowFN(
                      item.id,
                      "https://rigi.ge/laravel/ecom/" + item.img_path
                    )
                  }
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </Button>
              </div>
            </>
          );
        })}
      </div>
      <Pagination
        hideDisabled={true}
        activePage={paginateData.current_page}
        itemsCountPerPage={paginateData.per_page}
        totalItemsCount={paginateData.total}
        pageRangeDisplayed={5}
        onChange={(pageNumber) => handlePageChange(pageNumber)}
      />
    </>
  );
}

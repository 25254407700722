import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      navigate("/home");
    }
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  async function login() {
    let user = { email, password };
    fetch("https://rigi.ge/laravel/ecom/api/login", {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result.error);
          if (result.error !== "email or password wrong") {
            localStorage.setItem("user-info", JSON.stringify(result));
            navigate("/");
          } else {
            setModalShow(true);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error");
        }
      );
  }
  return (
    <div className="loginPageWrapper">
      <div className="loginPage">
        <div className="">
          <h1> Login Admin Page </h1>

          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            placeholder="user"
          />
          <br />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            placeholder="password"
          />
          <br />
          <button onClick={login} className="btn btn-primary">
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
